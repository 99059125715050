<template>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col>
        <b-container class="bg-white">
          <b-row class="mb-4">
            <b-col>
              <menu-workflow :activekey="'diagram'" />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div id="mermaid" class="mermaid" v-html="graph">
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ac from '@/libs/accesscontrol'
import MenuWorkflow from '@/components/workflow/article/Menu.vue'
import mermaid from 'mermaid'

export default {
  components: {
    MenuWorkflow
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.permissions.workflow = ac.can(this.user.acgroups).readAny('workflow').granted
    if (!this.permissions.workflow) {
      this.$router.push({ name: 'Start' })
    }
  },
  mounted: function () {
    this.draw()
  },
  data () {
    return {
      graph: `
        flowchart LR
          A{Start} ---> Edit
          Edit <---> Review
          Edit <--> Delete
          Review <---> Approve
          Approve <---> Z{Publish}
          Approve --> Edit
      `,
      permissions: {}
    }
  },
  methods: {
    draw: function () {
      mermaid.initialize({ theme: 'default', startOnLoad: false, securityLevel: 'loose' })
      const container = document.getElementById('mermaid')
      mermaid.init(this.graph, container)
    }
  },
  watch: {
  }
}
</script>

<style>
</style>
